import { forwardRef } from "react";
import combineClassnames from "classnames";
import classNames from "classnames/bind";
import styles from "./kicker.module.css";

export const kickerKinds = [
  "underlined",
  "underlined-sugarfree",
  "bare",
  "tag-sugarfree",
] as const;

export type KickerKind = (typeof kickerKinds)[number];

export interface KickerProps {
  text: string | null;
  className?: string;
  kind: KickerKind;
}

const cx = classNames.bind(styles);

export const Kicker = forwardRef<HTMLSpanElement, KickerProps>(
  ({ text, className, kind }, ref) => (
    <span
      ref={ref}
      className={combineClassnames(
        className,
        cx("container", `container--${kind}`),
      )}
    >
      {text}
    </span>
  ),
);
