import { Asset } from "../../__generated__/contentful-types";
import { getContentfulImageUrl } from "../../helpers/images/getContentfulImageUrl";
import { notNull } from "../../helpers/notNull";

/**
 * These sizes are more-or-less arbitrary, they were chosen because they
 * represent some typical viewport widths according to:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * More options results in more granular performance optimisation, but at the
 * cost of cache-hit probability.
 */
const TYPICAL_VIEWPORT_WIDTHS = [
  320, 480, 768, 1024, 1200, 1366, 1440, 1536, 1920, 2560,
];

/**
 * This image component leverages `srcset` to let the browser choose the most
 * appropriate image size for the current viewport (including viewport width and
 * device-pixel-ratio). It also serves a higher-than-necessary quality image if
 * there happens to be one stored in the browser cache.
 */
export function ContentfulResponsiveImage({
  asset,
  className,
  alt,
  sizes,
  loading = "lazy",
  widthVariants,
}: {
  asset: Asset;
  className?: string;
  alt?: string | null;
  sizes?: string;
  widthVariants?: number[];
  loading?: "lazy" | "eager";
}) {
  const { width } = asset;

  const widths = widthVariants
    ? widthVariants
        .map<
          number[]
        >((widthVariant) => [widthVariant, Math.ceil(widthVariant * 1.5), widthVariant * 2])
        .flat()
    : TYPICAL_VIEWPORT_WIDTHS;

  return (
    <img
      loading={loading}
      className={className}
      alt={alt || ""}
      src={asset.url ?? undefined}
      sizes={sizes}
      srcSet={[
        // Exclude sizes larger than the original, because they would result
        // in a stretched image…
        ...(width !== null
          ? widths.filter((viewportWidth) => viewportWidth < width)
          : []),
        // … but include the original image itself
        width,
      ]
        .filter(notNull)
        .map((width) => {
          const url =
            asset.url &&
            getContentfulImageUrl(asset.url, {
              width,
            });
          return `${url} ${width}w`;
        })
        .join(", ")}
    />
  );
}
